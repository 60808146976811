(function() {
  "use strict";

  var slideshows = $('.cycle-slideshow');

  function transitionNext(index) {
    if (index === undefined || index === slideshows.length - 1) { index = 0; } else { index++; }

    slideshows.eq(index).cycle('next');
  }
  // optional: sort the slideshow collection based on the value of the data-index attribute
  Array.prototype.sort.call(slideshows, function(a, b) {
    a = $(a).data('index');
    b = $(b).data('index');
    return a < b ? -1 : a > b ? 1 : 0;
  });

  // bind to cycle-after to trigger next slideshow's transition
  // $('.cycle-thumbnail').on('cycle-before', function(e) {
  //   var index = slideshows.index(e.target);
  //   transitionNext(index);
  // });


  // $('.cycle-thumbnail').on('cycle-next', function(e) {
  //   $('.cycle-slideshow').cycle('next');
  // });

  // // trigger the initial transition after 1 second
  // setTimeout(transitionNext, 1000);

  var progress = $('#progress'),
    slideshow = $('.cycle-thumbnail');

  slideshow.on('cycle-initialized cycle-before', function(e, opts) {
    progress.stop(true).css('width', 0);
  });

  slideshow.on('cycle-initialized cycle-after', function(e, opts) {
    if (!slideshow.is('.cycle-paused')) {
      progress.animate({ width: '100%' }, opts.timeout, 'linear');
    }
  });

  slideshow.on('cycle-paused', function(e, opts) {
    progress.stop();
  });

  slideshow.on('cycle-resumed', function(e, opts, timeoutRemaining) {
    progress.animate({ width: '100%' }, timeoutRemaining, 'linear');
  });

})();
